body {
  background-color: #fafafa;
  height: 100%;
}

.App {
  padding: 2rem 4rem;
}

a {
  text-decoration: none;
}

span {
  text-align: center;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
  width: 80%;
}

h5 {
  text-align: center;
}

p {
  margin-top: 1rem;
}

li {
  list-style: none;
}

/* Navbar */
.App > .navbar {
  display: flex;
  justify-content: space-between;
  margin: 2rem 1.5rem;
}

.navbar .navbar-collapse {
  flex-grow: initial;
}

.navbar .me-auto {
  gap: 2rem;
  float: right;
  text-align: right;
  padding-right: 1rem;
  padding-top: 1rem;
}

.navbar .dropdown {
  margin-left: 6rem;
}

.navbar .cart {
  position: relative;
  float: right;
}

/* Footer */
.footer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  width: 100%;
}

/* Wrappers */
.App,
.home,
.art,
.film,
.blog,
.domination,
.news,
.contact {
  padding: 1rem 3rem 1rem 3rem;
}

/* Row Cards */
.home-card {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  padding: 1rem 5rem;
}

/* Home */
.home-text {
  max-width: 25vw;
  margin-left: 1.5rem;
}

.home-image > img {
  height: auto;
  width: 31vw;
}

/* Art */
.art-card {
  display: flex;
  flex-direction: column;
  margin: 2rem 4rem;
  max-width: fit-content;
}

.art-title {
  padding: 1rem 2.5rem;
  text-align: justify;
}

.art-image {
  padding: 1rem 2rem;
}

.art-solo {
  display: flex;
  justify-content: center;
}

.art-duo {
  display: flex;
  align-items: center;
  max-width: 48%;
  height: auto;
  gap: 1rem;
  margin: 1rem 0;
}

.duo-desc,
.trio-desc {
  text-align: center;
  padding: 1rem 5rem;
}

.art-trio {
  display: flex;
  max-width: 31%;
  height: auto;
  gap: 1rem;
  margin: 1rem 0;
}

.a-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

/* Subscribre */
.subscribe {
  margin: 6rem 2rem 2rem 1rem;
}

.subscribeEmail {
  margin: 0.4rem 0;
}

.subscribeButton {
  margin-top: 1.5rem;
  min-width: 10vw;
  border-radius: 5px;
  background: #1976d2;
  color: white;
  padding: 0.5rem;
  border: 1px solid grey;
}

/* Home - Insta */
.home .home-insta {
  height: 40vh;
  width: 100%;
}

/* Column cards */
.film-card,
.blog-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 3rem;
}

/* Film */
iframe {
  width: 80%;
  min-height: 75vh;
}

.film-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 6rem;
}

.film-credits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Music */
.music-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 2rem 6.5rem;
}

.music-description {
  text-align: center;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
  width: 80%;
}

/* Domination */
.float-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  float: right;
  overflow: visible;
  bottom: 16rem;
  width: 33vw;
  border: 1px solid black;
  background: rgba(187 180 180 / 87%);
}

.domination-cover-image > img {
  height: 75vh;
  width: 35vw;
}

.domination-card {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin: 2rem 0;
}

.domination-card-image {
  min-width: 45vw;
}

.domination-card-description {
  padding: 1.5rem;
}

.dom-subscribe {
  padding: 1rem;
}

.dom-button {
  margin-top: 1.5rem;
  min-width: 10vw;
  border-radius: 5px;
  background: #1976d2;
  color: white;
  padding: 0.5rem;
  border: 1px solid grey;
}

.bdsm {
  text-align: center;
}

.blog-link {
  text-transform: capitalize;
  margin-top: 3rem;
}

/* Blog */

.b-title,
.b-title-data {
  padding: 3rem;
  text-align: center;
}

.b-text,
.b-text-data {
  padding: 2rem;
  text-align: justify;
  line-height: 2rem;
  letter-spacing: 0.1rem;
}

.b-data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* news */
.news-data {
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 2px 1px 1px rgb(16, 16, 16);
  padding: 1rem 1rem;
  transform: scale(0.9);
}
.news-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  height: 30vh;
}

.news-text, .news-text > a {
  width: 60vw;
  color: black;
}

.news-title > h2 {
  white-space: wrap;
  margin-bottom: 1rem;
}

.news-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 50vw;
}

/* contact */
.form-group {
  margin: 1rem 0 1rem 0;
  max-width: 30vw;
}



@media screen and (max-width: 720px) {
  /* wrappers */
  .App,
  .home,
  .art,
  .film,
  .blog {
    padding: 1rem 0.5rem 1rem 0.5rem;
  }

  /* footer */
  .footer > .email {
    margin-right: 3rem;
  }

  .footer > a {
    margin-right: 1rem;
  }

  /* home */
  .home-card {
    display: flex;
    flex-direction: column;
    padding: 0;
    /* margin-bottom: 3rem; */
  }

  .home-text {
    display: contents;
  }

  .home-image {
    width: 77vw;
  }

  .home-image > img {
    width: 65%;
    height: auto;
    margin: 1rem 2rem 1rem 3rem
  }

  .home-description {
    padding: 1rem;
  }

  .subscribe {
    margin: 4rem 2rem 2rem 1rem;
  }


  /* art */
  .art-title {
    padding: 1rem 1rem;
  }

  .art-card {
    margin: 2rem 2rem;
  }

  .art-image {
    padding: 1rem 1rem;
  }

  .art-trio {
    max-width: 30%;
  }

  .duo-desc,
  .trio-desc {
    text-align: center;
    padding: 1rem 1rem;
  }

  span {
    line-height: 1rem;
  }

  /* blog */
  .b-title-data {
    padding: 2rem;
  }

  /* domination */
  .float-title {
    transform: scale(0.7);
    bottom: 13rem;
    width: 38vw;
  }

  .domination-card {
    display: contents;
  }

  .dom-subscribe {
    display: flex;
    flex-direction: column;
    margin-top: 7rem;
    padding: 1rem;
  }

  .mb-3 {
    min-width: 60vw;
  }

  .form-label {
    margin-bottom: 1.5rem;
  }

  .dom-button {
    min-width: 55vw;
    height: 6.5vh;
    border-radius: 5px;
    background: #1976d2;
    color: white;
    padding: 0.5rem;
    border: 1px solid grey;
  }

  /* film */
  .film-title {
    padding: 2rem 2rem;
  }

  /* music */
  .music-card {
    padding: 2rem 2rem;
  }

  iframe {
    width: 100%;
    min-height: 95%;
  }

  /* contact */
.form-group {
  max-width: 70vw;
}
}
